const testimonialMetaData = [
  {
    name: "description",
    content: "What People Say",
  },
  {
    name: "keywords",
    content: "testimonials; reviews;",
  },
];

export default testimonialMetaData;
